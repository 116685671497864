<template>
    <div class="app-container">
        <el-form :model="query" ref="queryForm" :inline="true" label-width="80px">
            <el-form-item label="名字" prop="name">
                <el-input v-model="query.name" placeholder="请输入名字" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <!-- <el-form-item label="广告时间" prop="queryAdvTime">
                <el-date-picker clearable size="small" v-model="queryAdvTime" type="daterange" value-format="yyyy-MM-dd"
                    range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" @change="queryDateChange">
                </el-date-picker>
            </el-form-item> -->
            <el-form-item label="广告状态" prop="status">
                <el-select v-model="query.status" placeholder="请选择状态" clearable size="small">
                    <el-option v-for="dict in dictType" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd"
                    v-hasPermi="['business:homeAdv:add']">新增
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
                    v-hasPermi="['business:homeAdv:edit']">修改
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
                    v-hasPermi="['business:homeAdv:remove']">删除
                </el-button>
            </el-col>
            <!-- <el-col :span="1.5">
                <el-button type="warning" plain icon="el-icon-download" size="mini" @click="handleExport"
                    v-hasPermi="['business:homeAdv:export']">导出
                </el-button>
            </el-col> -->
            <!-- <right-toolbar :showSearch.sync="reset" @queryTable="getList"></right-toolbar> -->
        </el-row>

        <el-table v-loading="loading" :data="list" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="名字" align="center" prop="name" />
            <el-table-column label="图片" align="center" prop="pic" width="100">
                <template v-slot="slot">
                    <img :src="slot.row.pic" width="64px" alt="image error">
                </template>
            </el-table-column>
            <!-- <el-table-column label="广告显示时间" align="center" prop="startTime" width="210">
                <template v-slot="slot">
                    {{ slot.row.startTime }}&nbsp;至&nbsp;{{ slot.row.endTime }}
                </template>
            </el-table-column> -->
            <!--      <el-table-column label="开始时间" align="center" prop="startTime" width="180">-->
            <!--        <template slot-scope="scope">-->
            <!--          <span>{{ parseTime(scope.row.startTime, '{y}-{m}-{d}') }}</span>-->
            <!--        </template>-->
            <!--      </el-table-column>-->
            <!--      <el-table-column label="结束时间" align="center" prop="endTime" width="180">-->
            <!--        <template slot-scope="scope">-->
            <!--          <span>{{ parseTime(scope.row.endTime, '{y}-{m}-{d}') }}</span>-->
            <!--        </template>-->
            <!--      </el-table-column>-->
            <el-table-column label="状态" align="center" prop="status" width="80">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.status == '1'?'success':'danger'">{{ scope.row.status == '1'?'启用':'禁用' }}</el-tag>
                </template>
            </el-table-column>
            <!-- <el-table-column label="点击数" align="center" prop="clickCount" width="100" /> -->
            <el-table-column label="跳转商品编号" align="center" prop="skuId" >
                <template #default="scope">
                    <span style="color: #67c23a">{{ scope.row.skuId }}</span>
                </template>
            </el-table-column>
            <el-table-column label="排序" align="center" prop="sort" width="60" />
            <el-table-column label="备注" align="center" prop="note" />
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)"
                        v-hasPermi="['business:homeAdv:edit']">修改
                    </el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)"
                        v-hasPermi="['business:homeAdv:remove']">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="query.current" :limit.sync="query.size"
            @pagination="getList" />

        <!-- 添加或修改首页轮播广告对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="700px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="150px">
                <el-form-item label="名字" prop="name">
                    <el-input v-model="form.name" placeholder="请输入名字" />
                </el-form-item>
                <el-form-item label="广告图片" prop="pic">
                    <!-- <el-upload class="adv-upload" :action="uploadUrl" :show-file-list="false" :headers="headers"
                        :on-success="uploadSuccess" :before-upload="uploadBefore" :on-error="uploadError">
                        <img v-if="form.pic" :src="form.pic" class="img-icon" alt="image error">
                        <i v-else class="el-icon-plus adv-uploader-icon"></i>
                    </el-upload> -->
                    <el-upload
                        action="uploadUrl"
                        list-type="picture-card"
                        :show-file-list="false" :headers="headers"
                        :on-success="uploadSuccess" :before-upload="uploadBefore" :on-error="uploadError">
                        <img v-if="form.pic" :src="form.pic" class="img-icon" alt="image error" style="width: 100%; height: 100%;">
                        <i v-else class="el-icon-plus"></i>
                    </el-upload>
                    <span style="font-size: 12px; color: red;">请上传比例为：347px:119px（或5:2）的图片规格</span>

                </el-form-item>
                <!-- <el-form-item label="广告显示时间" prop="startTime">
                    <el-date-picker size="small" v-model="advTime" type="daterange" value-format="yyyy-MM-dd"
                        range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" @change="dateChange">
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item label="跳转商品" prop="skuId">
                    <el-select v-model="form.skuId" placeholder="请选择跳转商品">
                        <el-option
                        v-for="item in productList"
                        :key="item.skuInfo.skuId"
                        :label="item.skuInfo.skuName"
                        :value="item.skuInfo.skuId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio :label="0">禁用</el-radio>
                        <el-radio :label="1">启用</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                    <el-input v-model.number="form.sort" placeholder="请输入排序" />
                </el-form-item>
                <el-form-item label="备注" prop="note">
                    <el-input v-model="form.note" type="textarea" placeholder="请输入备注" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getToken } from "@/utils/auth"
import api from '@/api/commonApi'

export default {
    name: 'Edit',
    data() {
        return {
            uploadUrl: process.env.VUE_APP_SERVER_URL + "/api/common/upload", // 上传的图片服务器地址
            headers: {    // 上传图片所需token头
                token: getToken()
            },
            // 模态框头
            title: '',
            // 是否显示弹出层
            open: false,
            // 列表
            list: [],
            // 类型
            dictType: [
                { label: '禁用', value: 0 },
                { label: '启用', value: 1 }
            ],
            // 总条数
            total: 0,
            // 遮罩层
            loading: true,
            // 查询
            query: {
                current: 1,
                size: 10,
                name: null,
                status: null,
                startTime: null,
                endTime: null,
            },
            // 选择
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 查询的时间范围
            queryAdvTime: [],
            // 表单参数
            form: {
                status: 1
            },
            // 广告开始时间和结束时间
            advTime: [],
            // 表单校验
            rules: {
                name: [
                    { required: true, message: '请输入广告名称', trigger: 'blur' },
                    { max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
                ],
                pic: [
                    { required: true, message: '请上传广告图片', trigger: 'blur' }
                ],
                startTime: [
                    { required: true, message: '请选择广告开始时间', trigger: 'blur' }
                ],
                endTime: [
                    { required: true, message: '请选择广告结束时间', trigger: 'blur' }
                ],
                skuId: [
                    { required: true, message: '请输入广告详情链接地址', trigger: 'blur' }
                ],
                sort: [{ type: 'number', required: true, message: '请输入排序号', trigger: 'blur' }]
            },
            // 商品列表
            productList: [],
        }
    },
    created() {
        this.getList()
        this.getProductList()
    },
    methods: {
        // 获取列表数据
        getList() {
            this.loading = true
            api.homeList(this.query).then(res => {
                console.log(res);
                this.total = res.total
                this.list = res.records
                this.loading = false
            })
        },
        // 获取商品列表
        getProductList() {
            api.getSpuList({
                current: 1,
                size: 1000
            }).then(res => {
                this.productList = res
            })
        },
        // 搜索
        handleQuery() {
            this.getList()
        },
        // 重置
        reset() {
            this.queryAdvTime = []
            this.advTime = [];
            this.form = {
                id: null,
                name: null,
                pic: null,
                startTime: null,
                endTime: null,
                status: "1",
                skuId: null,
                note: null,
                sort: 0,
            };
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.query.startTime = this.query.endTime = null;
            this.reset()
            this.handleQuery();
        },
        /** 多选框选中数据 */
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        /** 时间筛选 */
        queryDateChange(dateArr) {
            if (null != dateArr && dateArr.length == 2) {
                this.query.startTime = dateArr[0];
                this.query.endTime = dateArr[1];
            } else {
                this.query.startTime = this.query.endTime = null;
            }
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加首页轮播广告";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids
            api.homeAdvInfo(id).then(response => {
                this.form = response;
                this.advTime = [this.form.startTime, this.form.endTime];
                this.open = true;
                this.title = "修改首页轮播广告";
            });
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    if (this.form.id != null) {
                        api.editHomeAdv(this.form).then(response => {
                            this.$message.success("修改成功");
                            this.open = false;
                            this.getList();
                        });
                    } else {
                        api.addHomeAdv(this.form).then(response => {
                            this.$message.success("新增成功");
                            this.open = false;
                            this.getList();
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || this.ids;
            this.$confirm('是否确认删除首页轮播广告编号为"' + ids + '"的数据项？').then(function () {
                return api.delHomeAdv({ids: ids + ''});
            }).then(() => {
                this.getList();
                this.$message.success("删除成功");
            }).catch(() => {
            });
        },
        /** 上传成功回调方法 */
        uploadSuccess(res, file) {
            if (res.code == 200) {
                this.form.pic = res.data.url;
            } else {
                this.$message.error("图片插入失败");
            }
        },
        /** 图标上传前 */
        uploadBefore(file) {
            // 校验是否图片
            const notImg = 'image/jpeg,image/png,image/jpg,image/bmp,image/webp'.indexOf(file.type) < 0;
            if (notImg) {
                this.$message.error(`请上传图片类型的文件`);
                return false;
            }
            // 校检文件大小
            if (file.size / 1024 / 1024 > 5) {
                this.$message.error(`上传图标大小不能超过 5 MB!`);
                return false;
            }
            return true;
        },
        /** 文件上传异常 */
        uploadError() {
            this.$message.error("图片插入失败");
        },
        /** 日期改变 */
        dateChange(dateArr) {
            if (null != dateArr && dateArr.length == 2) {
                this.form.startTime = dateArr[0];
                this.form.endTime = dateArr[1];
            }
        },
    }
}

</script>